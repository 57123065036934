/*.breadcrumbs {*/
  /*height: 125px; }*/

.wrapper {
  max-width: 1630px;
  min-width: 200px;
  /*width: 100%;*/
  margin: 0 auto;
  position: relative;
  padding: 0 15px;
  outline: none; }

/* Blog Page*/
.wrap_blog {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-bottom: 50px;
  margin: 0 -26px; }
  .wrap_blog .item_blog {
    width: 360px;
    height: 520px;
    -webkit-border-radius: 12px;
            border-radius: 12px;
    border: 1px solid #ebedef;
    overflow: hidden;
    margin: 0 26px 44px;
    position: relative;
    cursor: pointer;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s; }
    .wrap_blog .item_blog:hover {
      -webkit-box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.07);
              box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.07); }
    .wrap_blog .item_blog img {
      display: block; }
    .wrap_blog .item_blog .text_blog {
      padding: 25px 23px;
      font-family: 'LetoSans-Thin';
      font-size: 13px;
      line-height: 20px;
      color: #767676;
      margin-top: 7px; }
      .wrap_blog .item_blog .text_blog .date_blog {
        font-family: 'LetoSans'; }
      .wrap_blog .item_blog .text_blog .title_blog {
        font-family: 'LetoSans-Bold';
        font-size: 18px;
        line-height: 24px;
        color: #000;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin: 18px 0; }
    .wrap_blog .item_blog .foot_blog {
      background: #fff;
      height: 66px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0; }
    .wrap_blog .item_blog .btn {
      width: 164px;
      height: 35px;
      line-height: 35px;
      background: #f71819;
      -webkit-border-radius: 6px;
              border-radius: 6px;
      padding: 0 20px;
      display: block;
      color: #fff;
      text-decoration: none;
      font-family: 'LetoSans-Bold';
      font-size: 14px;
      letter-spacing: 1px;
      position: absolute;
      bottom: 25px;
      right: 10px;
      opacity: 0;
      -webkit-transition: all .3s;
      -o-transition: all .3s;
      transition: all .3s; }
      .wrap_blog .item_blog .btn:after {
        content: '';
        width: 14px;
        height: 16px;
        background: url(../images/articles/arr_btn.png) no-repeat;
        position: absolute;
        top: 10px;
        right: 13px; }
    .wrap_blog .item_blog:hover .btn {
      opacity: 1; }
  .wrap_blog .item_blog_1:before {
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(8, 26, 70, 0.5);
    position: absolute;
    top: 0;
    left: 0; }
  .wrap_blog .item_blog_1 .text_blog {
    position: absolute;
    top: 0;
    left: 0;
    color: #fff; }
    .wrap_blog .item_blog_1 .text_blog .title_blog {
      color: #fff; }
    .wrap_blog .item_blog_1 .text_blog p {
      opacity: 0;
      height: 275px;
      overflow: hidden;
      -webkit-transition: all .3s;
      -o-transition: all .3s;
      transition: all .3s; }
  .wrap_blog .item_blog_1 .like_blog {
    position: absolute;
    bottom: 40px;
    left: 23px; }
  .wrap_blog .item_blog_1 .foot_blog {
    background: transparent; }
  .wrap_blog .item_blog_1 .btn {
    background: #f4f4f4;
    color: #767676; }
    .wrap_blog .item_blog_1 .btn:after {
      background: url(../images/articles/arr_btn_dark.png) no-repeat; }
  .wrap_blog .item_blog_1:hover .text_blog p {
    opacity: 1; }
  .wrap_blog .item_blog_2 img {
    height: 210px; }
  .wrap_blog .item_blog_2 .text_blog {
    padding-top: 15px; }
  .wrap_blog .item_blog_2:hover .text_blog p {
    opacity: 1; }

.like_blog {
  font-family: 'LetoSans';
  font-size: 13px;
  color: #fff;
  margin: 0;
  padding: 0; }
  .like_blog li {
    list-style-type: none;
    padding-left: 36px;
    line-height: 23px;
    display: inline-block; }
    .like_blog li.eye {
      background: url(../images/articles/icon_eye.png) no-repeat center left;
      margin-right: 24px; }
    .like_blog li.comments {
      background: url(../images/articles/icon_comments.png) no-repeat center left; }

.head_info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-family: 'LetoSans';
  font-size: 13px;
  line-height: 20px;
  color: #767676; }

/* Article Page */
.wrap_article {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding-bottom: 85px; }
  .wrap_article .head_info {
    margin-bottom: 25px; }
  .wrap_article .like_blog {
    color: #767676; }
    .wrap_article .like_blog li.eye {
      background: url(../images/articles/icon_eye_dark.png) no-repeat center left;
      margin-right: 24px; }
    .wrap_article .like_blog li.comments {
      background: url(../images/articles/icon_comments_dark.png) no-repeat center left; }
  .wrap_article .content_article {
    width: 70%;
    padding-right: 36px; }




    .wrap_article .content_article p {
      font-family: 'LetoSans-Thin';
      font-size: 15px;
      line-height: 27px;
      color: #767676;
      padding-right: 10px;
      margin: 10px 0; }

.end {
  padding-bottom: 25px;
}
.begin {
  padding-top: 25px;
}
    .wrap_article .content_article ol {
     font-family: 'LetoSans';
     font-size: 15px;
     line-height: 27px;
     color: #767676;
     padding-right: 10px;
     padding-left: 30px;
     margin: 20px 0; }
    .wrap_article .content_article h2 {
      font-family: 'LetoSans';
      font-size: 24px!important;
      line-height: 27px;
      color: #000;
      text-transform: uppercase;
      margin: 10px 0 2px; }
    .wrap_article .content_article .image_content {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
              justify-content: space-between;
      margin: 38px 0 45px; }
    .wrap_article .content_article img{
      border-radius: 10px;
      float: left;
      overflow: hidden;
      margin-left: 10px;
      margin-right: 30px;
      width: 49%;}
    .wrap_article .content_article .image_content_1 a {
      width: 49%; }
      .wrap_article .content_article .image_content_1 a img {
        width: 100%; }
    .wrap_article .content_article .image_content_2 a {
      width: 32%; }
      .wrap_article .content_article .image_content_2 a img {
        width: 100%; }
    .wrap_article .content_article .block_content {
      overflow: hidden;
      margin: 40px 0 28px; }
      .wrap_article .content_article .block_content img {
        float: left;
        margin-right: 30px;
        margin-top: 7px;
        width: 49%; }
      .wrap_article .content_article .block_content h2 {
        margin-top: 0;
        overflow: hidden; }
      .wrap_article .content_article .block_content p {
        overflow: hidden;
        padding-right: 0; }
    .wrap_article .content_article .hashteg_article {
      padding: 0 23px;
      margin: 0;
      background: #e8ecef;
      line-height: 50px;
      -webkit-border-radius: 7px;
              border-radius: 7px;
      margin: 35px 0 63px; }
      .wrap_article .content_article .hashteg_article li {
        display: inline-block;
        font-family: 'LetoSans-Thin';
        font-size: 14px;
        color: #767676;
        text-transform: uppercase;
        margin-right: 5px; }
    .wrap_article .content_article .comments_article {
      margin-top: 30px;
    }
    .wrap_article .content_article .comments_article .title_comments {
      font-family: 'LetoSans-Bold';
      font-size: 24px;
      color: #444343;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 30px; }
      .wrap_article .content_article .comments_article .title_comments span {
        font-size: 16px;
        color: #767676; }
    .wrap_article .content_article .comments_article .wrap_comments {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
          -ms-flex-pack: end;
              justify-content: flex-end;
      margin-bottom: 28px; }
    .wrap_article .content_article .comments_article .item_comments {
      width: 100%;
      background: #e8ecef;
      border: 1px solid #ebedef;
      -webkit-border-radius: 12px;
              border-radius: 12px;
      padding: 35px 35px 20px 42px;
      margin-bottom: 30px;
      overflow: hidden;
      -webkit-box-shadow: 0 8px 20px 5px #e4e6e9;
              box-shadow: 0 8px 20px 5px #e4e6e9; }
    .wrap_article .content_article .comments_article .item_comments_1 {
      background: #f5f5f5;
      max-width: 1010px;
      position: relative; }
      .wrap_article .content_article .comments_article .item_comments_1:before {
        content: '';
        width: 3px;
        height: 100%;
        background: #f71819;
        position: absolute;
        top: 0;
        left: 0; }
    .wrap_article .content_article .comments_article .head_info {
      color: #000;
      margin-bottom: 15px; }
    .wrap_article .content_article .comments_article .text_comments {
      font-family: 'LetoSans-Thin';
      font-size: 13px;
      line-height: 20px;
      color: #767676;
      margin-bottom: 20px; }
    .wrap_article .content_article .comments_article .link_comments {
      font-family: 'LetoSans';
      font-size: 13px;
      color: #f71819;
      letter-spacing: 1px; }
  .wrap_article .list_article {
    /*width: 30%;*/
    background: #e8ecef;
    -webkit-border-radius: 12px;
            border-radius: 12px;
    position: relative; }



    .wrap_article .list_article .wrap_list_article {
      padding: 32px 15px 0 25px; }
    .wrap_article .list_article .item_list_article {
      border-bottom: 1px solid #dfdfdf;
      margin-bottom: 25px; }
      .wrap_article .list_article .item_list_article:last-child {
        border-bottom: none;
        margin-bottom: 30px; }
    .wrap_article .list_article .head_info {
      margin-bottom: 10px; }
    .wrap_article .list_article .title_article a{
      font: 18px 'LetoSans-Bold';
      text-transform: uppercase;
      margin-bottom: 20px;}
    .wrap_article .list_article p {
      font-family: 'LetoSans-Thin';
      font-size: 13px;
      line-height: 20px;
      color: #767676;
      padding: 20px 20px 20px 0;
    }
    .wrap_article .list_article .form_article {
      background: #40ae49;
      color: #fff;
      padding: 22px 25px 17px;
      -webkit-border-radius: 0 0 12px 12px;
              border-radius: 0 0 12px 12px; }
      .wrap_article .list_article .form_article .title_form {
        font-family: 'LetoSans';
        font-size: 13px;
        margin-bottom: 25px; }
      .wrap_article .list_article .form_article form {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
                justify-content: space-between; }
        .wrap_article .list_article .form_article form input {
          width: 100%;
          height: 36px;
          line-height: 36px;
          -webkit-border-radius: 7px;
                  border-radius: 7px;
          border: none;
          font-family: 'LetoSans-Thin';
          font-size: 13px;
          color: #767676;
          padding: 0 20px;
          margin-bottom: 10px;
          display: inline-block; }
        .wrap_article .list_article .form_article form input[type="submit"] {
          min-width: 152px;
          width: 31%;
          height: 36px;
          line-height: 36px;
          background: #f71819;
          border: none;
          -webkit-border-radius: 7px;
                  border-radius: 7px;
          font-family: 'LetoSans';
          font-size: 14px;
          color: #fff;
          letter-spacing: 1px;
          cursor: pointer;
          -webkit-transition: all .3s;
          -o-transition: all .3s;
          transition: all .3s; }
          .wrap_article .list_article .form_article form input[type="submit"]:hover {
            background: #dc0203; }

.hidden {
  display: none; }

.form_comments {
  background: #e8ecef;
  -webkit-border-radius: 7px;
          border-radius: 7px;
  color: #fff;
  padding: 30px 22px 27px;
  max-width: 1084px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin: 40px auto;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  position: relative; }
  .form_comments .title_form {
    font-family: 'LetoSans-Thin';
    font-size: 28px;
    margin-bottom: 30px;
	color: #000;	}
  .form_comments form textarea {
    width: 100%;
    height: 140px;
    -webkit-border-radius: 7px;
            border-radius: 7px;
    resize: none;
    margin-bottom: 25px;
	margin-top: 21px;
    font-family: 'LetoSans';
    font-size: 14px;
    color: #000;
    padding: 20px 30px;
	box-shadow: 1px 1px 3px 0 rgba(88,99,117,.1);
    border: none;
    background: #fff;
    }

	.error {
	color: red;
    font-size: 12px;
}
	.form_comments form input {
    width: 100%;
    -webkit-border-radius: 7px;
            border-radius: 7px;
    margin-bottom: 25px;
    font-family: 'LetoSans';
    font-size: 14px;
    color: #000;
    padding: 20px 30px;
    box-shadow: 1px 1px 3px 0 rgba(88,99,117,.1);
    border: none;
    }

  .form_comments form button {
    width: 340px;
    height: 60px;
    line-height: 60px;
    background: #f71819;
    border: none;
    -webkit-border-radius: 7px;
            border-radius: 7px;
    display: block;
    margin: 0 auto;
    font-family: 'LetoSans';
    font-size: 18px;
    color: #fff;
    letter-spacing: 1px;
    cursor: pointer;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s; }
    .form_comments form button:hover {
      background: #dc0203; }

/* Magnific-Popup Customize */
.mfp-ready .mfp-figure {
  opacity: 0; }

.mfp-zoom-in .mfp-figure,
.mfp-zoom-in .mfp-iframe-holder .mfp-iframe-scaler {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  -webkit-transform: scale(0.95);
      -ms-transform: scale(0.95);
          transform: scale(0.95); }

.mfp-zoom-in.mfp-bg,
.mfp-zoom-in .mfp-preloader {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }

.mfp-zoom-in.mfp-image-loaded .mfp-figure {
  opacity: 1;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1); }

.mfp-zoom-in.mfp-ready .mfp-iframe-holder .mfp-iframe-scaler {
  opacity: 1;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1); }

.mfp-zoom-in.mfp-ready.mfp-bg,
.mfp-zoom-in.mfp-ready .mfp-preloader {
  opacity: 0.5; }

.mfp-zoom-in.mfp-removing .mfp-figure,
.mfp-zoom-in.mfp-removing .mfp-iframe-holder .mfp-iframe-scaler {
  -webkit-transform: scale(0.95);
      -ms-transform: scale(0.95);
          transform: scale(0.95);
  opacity: 0; }

.mfp-zoom-in.mfp-removing.mfp-bg,
.mfp-zoom-in.mfp-removing .mfp-preloader {
  opacity: 0; }

.mfp-iframe-scaler {
  overflow: visible; }

.mfp-zoom-out-cur {
  cursor: auto; }

.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: pointer; }

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
  right: -25px;
  top: 5px; }

.mfp-close-btn-in .mfp-close {
  color: #fff;
  opacity: .3;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease; }

.mfp-close-btn-in .mfp-close:hover {
  opacity: .6; }

.mfp-close-btn-in .mfp-close {
  color: transparent;
  opacity: 1;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease; }

.mfp-close-btn-in .mfp-close:hover {
  opacity: 1; }

.my-mfp-zoom-in .form_comments {
  opacity: 0;
  -webkit-transform: scale(0.8);
      -ms-transform: scale(0.8);
          transform: scale(0.8); }

/* animate in */
.my-mfp-zoom-in.mfp-ready .form_comments {
  opacity: 1;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1); }

/* animate out */
.my-mfp-zoom-in.mfp-removing .form_comments {
  -webkit-transform: scale(0.8);
      -ms-transform: scale(0.8);
          transform: scale(0.8);
  opacity: 0; }

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.4; }

/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0; }

@media only screen and (max-width: 1450px) {

.wrap_blog .item_blog {
    width: 329px;
    height: 520px;
    margin: 0 14px 44px;
}

}

@media only screen and (max-width: 1350px) {
.wrap_blog .item_blog {
    width: 305px;
    height: 572px;
    margin: 0 14px 44px;
}
}

@media only screen and (max-width: 1240px) {
.wrap_article .list_article .form_article form input
  .wrap_article .wrap_read_more {
    width: 100%;
  }
  .wrap_article .content_article {
    width: 100%;
    margin-bottom: 60px;
    padding-right: 0; }
  .wrap_article .list_article {
    width: 100%; }


	}

@media only screen and (max-width: 990px) {
  .wrap_blog {
    -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
            justify-content: space-around;
    margin: 0; }
    .wrap_blog .item_blog {
      /* width: 48%; */
      max-width: 358px;
      margin: 0 0 44px; }
  .wrap_blog .item_blog .foot_blog {
    height: 62px; }
  .wrap_article .content_article .block_content img {
    float: none;
    margin-bottom: 20px; } }

@media only screen and (max-width: 560px) {
  .wrap_blog .item_blog {
    width: 100%; } }

@media only screen and (max-width: 480px) {
  .wrap_article .list_article .form_article form input {
    width: 100%; }
  .wrap_article .list_article .form_article form button {
    margin: 0 auto; }
  .wrap_article .like_blog li.eye {
    margin-right: 12px; } }

@media only screen and (max-width: 410px) {
  .form_comments form button {
    width: 100%; } }
.wrap_article .content_article .screen_8 {
  border: none;
}
.wrap_article .content_article .screen_8 img {
  width: 100%;
  margin-left: 0!important;
  margin-right: 0!important;
  border-radius: unset;
  float: unset;
}
.wrap_article .content_article .screen_8 .column {
  -ms-flex: 33.333%;
  flex: 33.333%;
  max-width: 33.333%;
}


.owners__container {
  gap: 18px;
}
.owners__item {
  width: 32%;
  min-height: 715px;
  background: #fff;
  box-shadow: 0px 2.26389px 56.5972px rgba(145, 145, 145, 0.15);
  border-radius: 10px;
  overflow: hidden;
}
.owners__item-img {
  height: 326px;
  width: 100%;
  background-size: cover;
  background-position-y: 30%;
}
.owners__item-info {
  padding: 30px;
  height: 389px;
  display: flex;
  flex-wrap: wrap;
}
.owners__item-title {
  font-size: 25px;
  font-weight: 100;
  line-height: 1.2;
  width: 100%;
}
.owners__item-text {
  padding-top: 13px;
  color: #4B4B4B;
  font-size: 17px;
  width: 100%;
}
.owners__item-buttons {
  width: 100%;
  align-self: flex-end;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: space-between;
}
.owners__item-buttons  a {
  transition: 0.2s;
}
.owners__item-bottom {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.owners-button {
  padding: 15px 0;
  text-align: center;
  display: inline-block;
}
.Apple_Chrome .owners-button, .Apple_Safari .owners-button {
  padding-top: 20px;
}
.green-button-full {
  background-color: #40AE49;
  color: #fff;
  border: 2px solid #40AE49;
  border-radius: 5px;
  width: 100%;
}
.green-button-half {
  background-color: #40AE49;
  color: #fff;
  border: 2px solid #40AE49;
  border-radius: 5px;
  width: 48%;
}
.light-button-half {
  background-color: white;
  color: #40AE49;
  border: 2px solid #40AE49;
  border-radius: 5px;
  width: 48%;
}
.green-button-full:hover, .green-button-half:hover {
  color: #40AE49;
  background: white;
}
.light-button-half:hover {
  color: white;
  background: #40AE49;
}
.red-button-half {
  background-color: #F71819;
  color: #fff;
  border: 2px solid #F71819;
  border-radius: 5px;
  width: 48%;
}
.red-button-half:hover {
  color: #F71819;
  background: white;
}
.owners__item-sublinks {
  padding: 12px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
  margin-bottom: -25px;
}
.owners__item-sublinks:before {
  content: '';
  border: 1.13194px solid #D1D1D1;
  width: 115%;
  margin: 0 -20px;
}
.owners__item-sublinks span, .owners__item-sublinks a {
  color: #40AE49;
}
.owners__item-sublinks i {
  font-style: normal;
}
.owners-q {
  margin-top: 100px;
}
.owners-grid {  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 13px;
  grid-auto-flow: row;
  grid-template-areas:
          "owners__item-1 owners__item-2 owners__item-3 owners__item-3"
          "owners__item-1 owners__item-4 owners__item-4 owners__item-5"
          "owners__item-6 owners__item-6 owners__item-7 owners__item-8";
}

.owners__item-1 { grid-area: owners__item-1; }

.owners__item-2 { grid-area: owners__item-2; }

.owners__item-3 { grid-area: owners__item-3; }

.owners__item-4 { grid-area: owners__item-4; }

.owners__item-5 { grid-area: owners__item-5; }

.owners__item-6 { grid-area: owners__item-6; }

.owners__item-7 { grid-area: owners__item-7; }

.owners__item-8 { grid-area: owners__item-8; }

.owners-grid__item {
  position: relative;
  background-size: cover;
  min-height: 372px;
  padding: 45px 30px;
}
.owners-grid__item:after {
  content: '';
  position: absolute;
  bottom: 0;
  background: linear-gradient(360deg, #232323 0%, rgba(48, 48, 48, 0) 100%);
  width: 100%;
  height: 40%;
  left: 0;
  z-index: 1;
}
.owners-grid__item-text {
  position: absolute;
  font-size: 28px;
  line-height: 1.2;
  color: #fff;
  bottom: 20px;
  z-index: 2;
}
.case {
  margin-top: 40px;
}
.case-grid {  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 13px;
  grid-auto-flow: row;
  grid-template-areas:
          "case-grid__item-1 case-grid__item-1 case-grid__item-2 case-grid__item-3"
          "case-grid__item-4 case-grid__item-5 case-grid__item-5 case-grid__item-3"
          "case-grid__item-6 case-grid__item-7 case-grid__item-8 case-grid__item-8";
}

.case-grid__item-1 { grid-area: case-grid__item-1; }

.case-grid__item-2 { grid-area: case-grid__item-2; }

.case-grid__item-3 { grid-area: case-grid__item-3; }

.case-grid__item-4 { grid-area: case-grid__item-4; }

.case-grid__item-5 { grid-area: case-grid__item-5; }

.case-grid__item-6 { grid-area: case-grid__item-6; }

.case-grid__item-7 { grid-area: case-grid__item-7; }

.case-grid__item-8 { grid-area: case-grid__item-8; }
.case-grid__item {
  background-size: cover;
  width: auto;
  min-height: 350px;
  background-repeat: no-repeat;
  padding: 35px;
  -o-object-fit: cover;
  object-fit: cover;
  position: relative;
}
.case-grid__item:after {
  content: '';
  background: rgba(0,0,0,.5);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 0.25rem;
  z-index: 1;
}
.case-grid__item-title a {
  font-size: 34px;
  color: #fff;
  width: 100%;
  display: inline-block;
  line-height: 1;
  position: relative;
  z-index: 2;
}
.case-grid__item-price {
  font-size: 28px;
  color: #fff;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px;
  background: #40ae49;
  border-radius: 5px;
  margin-top: 15px;
  position: relative;
  z-index: 2;
}
.Apple_Chrome .case-grid__item-price, .Apple_Safari .case-grid__item-price {
  padding-top: 10px;
}
.case-grid__item-text {
  color: white;
  font-size: 18px;
  padding-top: 20px;
  position: relative;
  z-index: 2;
}

.owners__item-text {
  font-size: 16px;
}
@media(max-width: 1600px) {
  .owners-button {
    font-size: 14px;
  }
  span.green-arrow {
    position: absolute;
    right: 0;
  }
}
@media(max-width: 1200px) {
  .owners__item {
    width: 48%;
  }
  .owners-grid,  .case-grid {
    display: flex;
    flex-wrap: wrap;
  }
  .owners-grid__item, .case-grid__item {
    width: 100%;
  }
}
@media(max-width: 768px) {
  .owners__item {
    width: 100%;
    min-height: auto;
  }
  .owners__item-info {
    height: auto;
  }
  .owners__item-buttons {
    gap: 10px;
    margin-top: 20px;
  }
  .owners-button {
    width: 100%;
  }
  .owners__item-title {
    font-size: 20px;
  }
  .owners__item-sublinks {
    padding-top: 20px;
  }
  .Apple_Chrome .owners__item-sublinks, .Apple_Safari .owners__item-sublinks {
    padding-top: 25px;
    font-size: 13px;
  }
  .owners__item-sublinks:before {
    position: relative;
    bottom: 5px;
    display: inline-block;
  }
  .Apple_Chrome .owners__item-sublinks:before, .Apple_Safari .owners__item-sublinks:before {
    bottom: 5px;
  }
  .owners-grid__item-text {
    font-size: 22px;
  }
  .case-grid__item-price {
    font-size: 25px;
  }
}
@media(max-width: 400px) {
  .owners__item {
    min-height: auto;
  }
  .owners__item-info {
    height: auto;
  }
  .owners__item-bottom {
    margin: 10px 0;
  }
  .owners__item-sublinks:before {
    display: none;
  }
}


@media(min-width: 2000px) {
  .case-grid__item-text {
    font-size: 20px;
  }

  .case-grid__item-title {
    font-size: 30px;
  }

  .owners__item-text {
    font-size: 19px;
  }
}