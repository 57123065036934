.about, .advantage, .principle, .experts, .services, .contactform {
    font-family: 'LetoSans-Thin', sans-serif;
}
.about_btn {
    width: 279px;
    height: 54px;
    padding: 15px 35px;
    background: #40ae49;
   /*  border: 2px solid #fff; */
    border-radius: 4.5px;
    font-size: 18px;
    line-height: 133.3333333333%;
    text-align: center;
    color: #fff;
    cursor: pointer
}

@media(max-width: 992px) {
    .about__down {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .about__info {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 100% !important;
        flex: 0 0 100% !important
    }

    .about__img {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 100% !important;
        flex: 0 0 100% !important
    }

    .about__top {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .about__header {
        text-align: center
    }

    .about__down-title {
        text-align: center
    }

    .about__down-img {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 100% !important;
        flex: 0 0 100% !important
    }

    .about_btn {
        margin: 0 auto
    }
}

.about {
    background: #fff;
    padding-bottom: 50px
}

.about__top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 100px;
    padding-top: 10px
}

.about__info {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%
}

.about__header {
    margin-bottom: 44px
}

.about__header h1 {
    font-weight: 700;
    font-size: 50px;
    line-height: 120%
}

.about__text {
    font-size: 18px;
    line-height: 133.3333333333%;
    color: #4b4b4b
}

.about__title {
    margin: 30px 0 16px 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 141.6666666667%;
    color: #4b4b4b
}

.about__title span {
    color: #cd2125
}

.about__comment {
    position: relative;
    margin-top: 40px;
    padding-left: 76px;
    font-weight: 700;
    font-size: 24px;
    line-height: 141.6666666667%;
    color: #4b4b4b
}

.about__comment::before {
    position: absolute;
    content: url("/templates/leto/images/about/home.svg");
    left: 0;
    top: 0
}

.about__img img {
    margin: 0 auto;
    border-bottom: 1em solid rgba(64, 174, 73, .5);
    border-right: 1em solid rgba(64, 174, 73, .5)
}

.about__down {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	justify-content: space-between;
	gap: 40px;
}

.about__down-img {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    /* flex: 0 0 50% */
	width: 43%;

}

.about__down-img img {
    margin: 0 auto;
    border-top: 16px solid rgba(64, 174, 73, .5);
    border-right: 16px solid rgba(64, 174, 73, .5)
}

.about__down-text {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%
}

.about__down-text .about_btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    width: 226px
}

.about__down-text .about__text {
    margin-bottom: 81px
}

.about__down-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 127.7777777778%;
    margin-bottom: 16px;
	color: #444343;

}

@media(max-width: 992px) {
    .advantage__text {
        padding-left: 0 !important
    }

    .advantage__block {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse
    }

    .advantage__block .advantage__text {
        max-width: 100%;
        padding-left: 0
    }

    .advantage__block-pad {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .advantage__block-pad .advantage__text {
        max-width: 100%;
        padding-left: 0
    }

    .advantage__header h2 {
        font-size: 40px
    }
}

.advantage {
    margin: 50px 0 75px 0
}

.advantage__header {
    margin-bottom: 50px
}

.advantage__header h2 {
    font-weight: 700;
    font-size: 50px;
    line-height: 120%;
	color: #444343;
}

.advantage__block-pad {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.advantage__block-pad .advantage__text {
    padding-left: 67px
}

.advantage__block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.advantage__block .advantage__text {
    max-width: 706px;
	padding-right: 30px;
}

.advantage__img {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%
}

.advantage__img img {
    max-width: 100%
}

.advantage__text {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    font-size: 18px;
    line-height: 133.3333333333%;
    color: #4b4b4b
}

.advantage__text a {
    color: #51a152;
    text-decoration: none
}

.advantage__text-title {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 24px;
    line-height: 141.6666666667%
}

@media(max-width: 992px) {
    .principle__grid {
        grid-template-areas: "b b b""c c c""a a a" !important
    }

    .principle__header {
        text-align: center
    }

    .principle__service-img {
        display: none
    }

    .principle__service-info {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 1 100% !important;
        flex: 0 1 100% !important;
        margin: 30px 0 30px 36px !important
    }

    .principle__comfort {
        max-width: 100%;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .principle__trust {
        max-width: 100%;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }
}

.principle {
    padding: 50px 0;
    background: #e5e5e5
}

.homestage {
    padding: 30px 0;
    background: #e5e5e5;
    margin: 30px 0;
}

.principle__header {
    margin-bottom: 50px
}

.principle__header h2 {
    font-weight: 700;
    font-size: 50px;
    line-height: 120%;
	color: #444343;
	
}

.principle__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.principle__item:not(:last-child) {
    margin-right: 16px
}

.principle__item-title {
    font-weight: 700;
    font-size: 24px!important;
    line-height: 141.6666666667%
}

.principle__service {
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch
}

.principle__service-info {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    margin: 30px 0 30px 53px
}

.principle__service-info p {
    font-size: 18px;
    line-height: 133.3333333333%;
    color: #4b4b4b
}



.principle__service-info img {
    margin-bottom: 20px
}

.principle__service-img img {
    display: block
}

.principle__trust {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 530px;
    background-color: #fff;
    margin-bottom: 16px;
    padding: 30px 30px 30px 35px
}

.principle__item-icon {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-right: 31px
}

.principle__item-text {
    font-size: 18px;
    line-height: 133.3333333333%;
    color: #4b4b4b
}

.principle__comfort {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 530px;
    background-color: #fff;
    padding: 30px 30px 30px 35px
}

.principle__grid {
    margin-top: 50px;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 2fr 16px 1fr 16px 1fr;
    grid-template-columns: 2fr 1fr 1fr;
    -ms-grid-rows: auto 16px auto 16px auto;
    grid-template-areas: "b b a""b b c""b b c";
    gap: 16px
}

.principle__grid-comf,
.principle__grid-trust,
.principle__grid-serv {
    background-color: #fff
}

.principle__grid-serv {
    -ms-grid-row: 1;
    -ms-grid-row-span: 5;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-area: b;
    -ms-grid-column-align: right;
    justify-self: right
}

.principle__grid-trust {
    -ms-grid-row: 1;
    -ms-grid-column: 5;
    grid-area: a
}

.principle__grid-comf {
    -ms-grid-row: 3;
    -ms-grid-row-span: 3;
    -ms-grid-column: 5;
    grid-area: c
}







@media (max-width: 992px) {
    .principle__grid-serv {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        -ms-grid-column-span: 3
    }

    .principle__grid-trust {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
        -ms-grid-column-span: 3
    }

    .principle__grid-comf {
        -ms-grid-row: 2;
        -ms-grid-column: 1;
        -ms-grid-column-span: 3
    }
}

@media(max-width: 992px) {
    .owners__img {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    .owners__inner {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse
    }

    .owners__header {
        text-align: center
    }
}

.owners {
    margin-top: 25px
}

.owners__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.owners__header {
    margin-bottom: 36px
}

.owners__header h2 {
    font-weight: 700;
    font-size: 50px;
    line-height: 120%;
	color: #444343;

}

.owners__info {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    font-size: 18px;
    line-height: 133.3333333333%;
    color: #4b4b4b
}

.owners__info-text {
    margin-bottom: 24px
}

.owners__info-comment {
    position: relative;
    padding-left: 60px
}

.owners__info-comment::after {
    position: absolute;
    content: url("/templates/leto/images/about/quote.svg");
    left: 0;
    top: 0
}
.home__info-comment {
    position: relative;
    padding-left: 60px
}
.home__info-comment::after {
    position: absolute;
    content: url("/templates/leto/images/about/quote.svg");
    left: 14px;
    top: 0;
}
p.home__info-comment_without {
    margin-left: 60px !important;
}

.lamp__info-comment {
    position: relative;
    padding-left: 60px
}
.lamp__info-comment::after {
    position: absolute;
    content: '';
    background:url("/templates/leto/images/about/lamp.svg") no-repeat;
    width: 51px;
    height: 53px;
    left: 5px;
    top: 0;
}

.owners__img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.owners__img-block p {
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 133.3333333333%
}

.owners__img-block img {
    border-radius: 50%
}

.owners__img-block:not(:last-child) {
    margin-right: 24px
}

@media(max-width: 576px) {
    .experts__inner {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.experts {
    padding: 50px 0 50px 0
}

.experts__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.experts__block {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 48%;
    flex: 0 0 48%
}

.experts__block .experts__text {
    margin-bottom: 50px
}

.experts__block .about_btn {
    display: block;
    width: 279px
}

.experts__title {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 36px;
    line-height: 127.7777777778%;
	color: #444343;

}

.experts__text {
    font-size: 18px;
    line-height: 133.3333333333%;
    color: #4b4b4b
}

@media(max-width: 992px) {

    .services__title,
    .services__list {
        font-size: 14px !important
    }

    .services__header {
        text-align: left;
    }
}

@media(max-width: 768px) {
    .services__inner {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .services__item {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
        background: #fff
    }

    .services__info {
        max-height: 100% !important
    }

    .about__top {
        padding-top: 30px!important;
        margin-bottom: 40px;
    }
    .advantage__text-title {
        font-size: 17px;
    }
}

@media(max-width: 620px) {
    .services__item {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }
}

.services {
    background: #e5e5e5;
    padding: 50px 0 75px 0
}

.services__header {
    margin-bottom: 50px
	
}

.services__header h2 {
    font-weight: 700;
    font-size: 50px;
    line-height: 120%;
	color: #444343;

}

.services__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 16px
}

.services__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 30%;
    flex: 1 0 30%
}

.services__more {
    padding: 20px 32px;
    display: none;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, rgba(255, 255, 255, 0)), color-stop(80%, #FFFFFF));
    background: -o-linear-gradient(rgba(255, 255, 255, 0) 20%, #FFFFFF 80%);
    background: linear-gradient(rgba(255, 255, 255, 0) 20%, #FFFFFF 80%)
}

.services__more a {
    font-size: 18px;
    line-height: 133.3333333333%;
    color: #40ae49;
    text-decoration: none
}

.services__item:hover .services__more {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
}

.services__img img {
    display: block;
    max-width: 100%
}

.services__info {
    width: 100%;
    height: 100%;
    padding: 30px 30px 30px 30px;
    background: #fff
}

.services__title {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 141.6666666667%;
	color: #444343;
}

.services__list {
    list-style: none;
    font-size: 18px;
    line-height: 133.3333333333%;
    color: #4b4b4b
}

.services__list li {
    position: relative;
    padding-left: 32px
}

.services__list li:not(:last-child) {
    margin-bottom: 16px
}

.services__list li::before {
    position: absolute;
    content: url("/templates/leto/images/about/check.svg");
    left: 0;
    top: 0
}

@media(max-width: 992px) {
    .contactform__inner {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }
}

@media(max-width: 700px) {
    .contactform__header {
        font-size: 35px !important
    }
}

.contactform {
    padding: 50px 0 50px 0;
    background: url("/templates/leto/images/about/contact-bg.png") no-repeat center bottom
}

.contactform__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.contactform__info {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 50%;
    flex: 1 0 50%
}

.contactform__header {
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 50px;
    line-height: 120%;
	color: #444343;
}

.contactform__text {
    font-size: 18px;
    line-height: 133.3333333333%;
    color: #4b4b4b
}

.contactform__link {
    font-weight: 700;
    font-size: 18px;
    line-height: 133.3333333333%;
    color: #ce2126
}

.contactform__item {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 30%;
    flex: 1 0 30%
}

.contactform__form .about_btn {
    float: right
}

.contactform__place:not(:last-child) {
    margin-bottom: 40px
}

.contactform__label {
    display: block;
    margin-bottom: .25rem
}

.contactform__input {
    display: block;
    width: 100%;
    height: 54px;
    padding: 15px 24px;
    font-family: inherit;
    font-size: 18px;
    font-weight: inherit;
    line-height: inherit;
    color: #4b4b4b;
    background: #ebebeb;
    border: 1px solid #c2c2c2;
    border-radius: 4.52778px;
    -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out
}

.contactform__input::-webkit-input-placeholder {
    font-size: 18px;
    line-height: 188.8888888889%;
    color: #4b4b4b
}

.contactform__input::-moz-placeholder {
    font-size: 18px;
    line-height: 188.8888888889%;
    color: #4b4b4b
}

.contactform__input:-ms-input-placeholder {
    font-size: 18px;
    line-height: 188.8888888889%;
    color: #4b4b4b
}

.contactform__input::-ms-input-placeholder {
    font-size: 18px;
    line-height: 188.8888888889%;
    color: #4b4b4b
}

.contactform__input::placeholder {
    font-size: 18px;
    line-height: 188.8888888889%;
    color: #4b4b4b
}


@media (max-width: 1500px) {

    .case-grid__item-title {
        font-size: 20px;
    }

.about__text {
    font-size: 15px;  
}

.about__comment {
    font-size: 19px;  
}

.advantage__text {
    font-size: 15px;  
}

.owners__info{
    padding-right: 50px;
}

.owners__info-text {
    font-size: 15px;  
}

.owners__info-comment {
    font-size: 15px;  
}


.about__down-text {
    padding-left: 63px;
}

.experts__text {
    font-size: 15px!important;  
}
.principle__header h2 {
font-size: 36px;
}

.advantage__header h2 {
font-size: 36px;
}


.owners__header h2 {
font-size: 36px;
}

.services__header h2 {
font-size: 36px;
}

.advantage__block-pad .advantage__text {
    padding-left: 27px;
}



}


@media (max-width: 1240px) {

.principle__grid {
        grid-template-areas: "b b b""c c c""a a a" !important
    }

.advantage__header {
    margin-bottom: 30px;
}

    .owners__item-text {
        font-size: 14px;
    }

    .owners__item-title {
        font-size: 24px;
    }
    .case-grid__item-title {
        font-size: 24px;
    }
    .principle__header {
        text-align: left;
    }

    .principle__service-img {
        display: none
    }

    .principle__service-info {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 1 100% !important;
        flex: 0 1 100% !important;
        margin: 30px 80px 30px 36px !important
    }

    .principle__comfort {
        max-width: 100%;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .principle__trust {
        max-width: 100%;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }


    .advantage__text {
        padding-left: 0 !important
    }

    .advantage__block {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse
    }

    .advantage__block .advantage__text {
        max-width: 100%;
        padding-left: 0
    }

    .advantage__block-pad {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .advantage__block-pad .advantage__text {
        max-width: 100%;
        padding-left: 0
    }

    .advantage__header h2 {
        font-size: 40px;
    }
	
	.advantage__img img {
    width: 100%;
	padding: 25px 0px 25px 0px;
	}
	.advantage__img {    
    width: 100%;
	}
	
	.owners__info {
    
    padding-top: 50px;
}

    .about__down {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
		gap: 80px;
    }

    .about__down-img {
        width: 100%;
    }

    .about__info {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 100% !important;
        flex: 0 0 100% !important
    }

    .about__img {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 100% !important;
        flex: 0 0 100% !important;
		padding-top: 40px;
    }

    .about__top {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
		padding-top: 40px;
    }

    .about__header {
        text-align: center
    }

    .about__down-title {
        text-align: left;
    }

    .about__down-img {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 100% !important;
        flex: 0 0 100% !important;
		order: 2;
    }

    .about_btn {
        margin: 0 auto
    }
	
	.about__down-text {
    /* padding-top: 40px; */
	order: 1;
}
	.about__down-text {
    padding-left: 0px;
}

   .owners__img {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: center;
    }

    .owners__inner {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse
    }

    .owners__header {
        text-align: center
    }
	
	.experts__inner {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
	
	.contactform__header {

    font-size: 36px;
}
	.services__info {
    
    padding: 30px 17px 30px 30px;
    
}
	
	.services__title {
   
    font-size: 20px;
    line-height: 126.666667%;
  
}

.services__list {
    font-size: 15px;

}
	
	
}

@media (max-width: 999px) {

.contactform__info {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 50%;
    flex: 1 0 100%;
}

.contactform__text {
 padding-bottom:25px;
}

.principle__item-text {
    font-size: 15px;
   padding-top: 15px;
}

.principle__service-info p {
    font-size: 15px;
    line-height: 133.3333333333%;
   
}


.principle__header h2 {
font-size: 36px;
}

.advantage__header h2 {
font-size: 36px;
}


.owners__header h2 {
font-size: 36px;
}

.services__header h2 {
font-size: 36px;
}

.about__down-title {
	font-size: 36px;
}

.experts__title {
	font-size: 36px;
}

.contactform__header {
    font-size: 36px;
}

}

@media (max-width: 768px) {

.about__comment {
    font-size: 15px;
}

.principle__service-info {
   
    margin: 30px 20px 30px 36px !important;
}


.principle__header h2 {
font-size: 24px;
}

.advantage__header h2 {
font-size: 24px;
}


.owners__header h2 {
font-size: 24px;
}

.services__header h2 {
font-size: 24px;
}

.about__down-title {
	font-size: 24px;
}

.experts__title {
	font-size: 24px;
}

.contactform__header {
    font-size: 24px;
}


}

@media (max-width: 600px) {
.owners__img-block:not(:last-child) {
    margin-right: 0px; 
	 margin-bottom: 20px; 
}
}


@media (max-width: 400px) {

.about__comment {
    font-size: 15px;
}

.principle__service-info {
   
    margin: 30px 20px 30px 36px !important;
}


.principle__header h2 {
font-size: 20px;
}

.advantage__header h2 {
font-size: 20px;
}


.owners__header h2 {
font-size: 20px;
}

.services__header h2 {
font-size: 20px;
}

.about__down-title {
	font-size: 20px;
}

.experts__title {
	font-size: 20px;
}

.contactform__header {
    font-size: 20px;
}


}
@media (max-width: 370px) {
    .about__title {
        font-size: 18px;
    }
}