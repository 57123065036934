.irs--square .irs-bar {
	height: 7px;
	/*top: 33px;*/
	background: #40ae49;
}

.irs--square .irs-handle {
	top: 26px;
	border: 3px solid #f71819;
}
