.table {
    width: 100%;
    margin-bottom: 20px;
    border: 5px solid #fff;
    border-top: 5px solid #fff;
    border-bottom: 3px solid #fff;
    border-collapse: collapse;
    outline: 3px solid #40ae49;
    font-size: 15px;
    background: #fff!important;
    text-align: justify;
    font: 16px LetoSans-Thin;
}
.table th {
    font-weight: 700;
    padding: 7px;
    background: #40ae49;
    border: none;
    text-align: center;
    font-size: 15px;
    border-top: 3px solid #fff;
    border-bottom: 3px solid #40ae49;
    color: #fff;
}
.table td {
    padding: 26px;
    border: none;
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff;
    font-size: 15px;
}
.table tbody tr:nth-child(even){
    background: #f8f8f8!important;
}

th.table_style {
    background: #e8ecef;
    color: #000;
}




.list {
    font-weight: 400;
    font-size: 15px;
    line-height: 173.3333333333%;
    color: #767676;
    padding: 0 !important;
}

.list_home {
    font-weight: 400;
    font-size: 15px;
    line-height: 173.3333333333%;
    color: #767676;
    padding: 0 !important;
    margin: 30px 0;
}

.list__el {
    padding-left: 15px;
    position: relative;
    list-style: none !important;
}

.list__el::before {
    position: absolute;
    left: 0;
    bottom: 40%;
    content: "";
    background: #40ae49;
    width: 6px;
    height: 6px;
    border-radius: 50%
}

.title {
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 144.4444444444% !important;
    margin-bottom: 16px !important;
    margin-top: 16px !important;
    color: #000 !important;
    font-family: 'LetoSans-Bold' !important;
}

.text {
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 173.3333333333% !important;
    color: #767676 !important;
}

summary {
    position: relative;
    list-style: none;
}

summary::after {
    position: absolute;
    content: url("/templates/leto/images/img/arrowdwn.svg");
    right: 0;
    top: 30%
}


.wrap_article .content_article img.img_40 {
    margin-left: 0;
    width: 40%;
}

.wrap_article .content_article img.img_50 {
    margin-left: 0;
    width: 50%;
}


.wrap_article .content_article div.img_list {
    margin-top: 25px;
}

.wrap_article .content_article ul.ul_50 {
    display: inline-block;
    width: 50%;
}

.wrap_article .content_article img.img_54 {
    margin-left: 0;
    width: 54%;
    margin-top: 5px;
    margin-bottom: 20px;
}
.wrap_article .content_article ul.ul_45 {
display: inline-block;
width: 45%;
}

.wrap_article .content_article .narrow_list li {
    margin-bottom: 7px;
    line-height: 21px !important;
}

@media (max-width: 620px) {
    summary::after {
        position: absolute;
        content: url("/templates/leto/images/img/arrowdwn.svg");
        left: -30px;
        top: 30%
    }
}

details .text {
    padding-right: 85px;
}

@media (max-width: 620px) {
    details {
        padding-left: 30px;
    }
}

.page__title {
    margin-bottom: 19px;
}

.page__title h1 {
    font-weight: 700;
    font-size: 34px;
}

.page__sub-title {
    font-weight: 400;
    color: #767676;
    line-height: 173.3333333333%;
    margin-bottom: 40px;
}

.page__img {
    padding-top: 2px;
    margin-bottom: 37px;
}

.page__img img {
    width: 100% !important;
}
.questions__img img, .risks__img img {
    width: auto !important;
}
.page__img img, .questions__img img, .risks__img img {
    max-width: 100%;
    float: unset !important;
    margin: 0 !important;
}
.questions__img img {
    max-width: 50px;
}

.steps {
    counter-reset: section;
}

.steps__title {
    margin-bottom: 16px;
}

.steps .steps__title h2 {
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 108.3333333333% !important;
    text-transform: uppercase;
}

.steps .steps__sub-title {
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 173.3333333333% !important;
    color: #767676 !important;
    margin-bottom: 64px !important;
}

.steps__step {
    max-width: 900px;
    margin-bottom: 60px;
}

.steps__step .title {
    margin-top: 22px !important;
}

.steps__step-span {
    display: block;
    position: relative;
    padding-left: 50px;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    color: #bdbdbd;
}

.steps__step-span::before {
    position: absolute;
    counter-increment: section;
    content: counter(section);
    width: 40px;
    height: 40px;
    left: 0px;
    bottom: -50%;
    border-radius: 50%;
    border: 2.5px solid #bdbdbd;
    font-weight: 700;
    font-size: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.Apple_Safari .steps__step-span::before, .Apple_Chrome .steps__step-span::before {
    padding-top: 5px;
}
.questions {
    padding-top: 27px;
    margin-bottom: 90px;
}

.questions__title {
    margin-bottom: 30px;
}

.questions__title h3 {
    font-weight: 700;
    font-size: 26px;
    line-height: 130.7692307692%;
    text-transform: uppercase;
}

.questions__item {
    margin-bottom: 56px;
    max-width: 900px;
}
.risks__img {
    width: 50%;
}
.risks__text {
    width: 44%;
}
.risks__title {
    margin-bottom: 40px;
}

.risks__title h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 141.6666666667%;
    text-transform: uppercase;
}

.risks__up {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 20px;
}
.risks__up, .risks__down {
    flex-wrap: wrap;
}
@media (max-width: 1024px) {
    .risks__text {
        width: 100%;
    }

    .risks__img {
        width: 100%;
    }

    .steps__custom .steps__step-span::before {
        bottom: 0;
    }

    .steps__custom .steps__step-span {
        height: 40px;
        display: inline-flex;
        align-items: center;
    }
}

@media (max-width: 768px) {
    .wrap_article .content_article img.img_40 {
        margin-bottom: 20px;
        width: 100%;
    }

    .wrap_article .content_article img.img_50 {
        margin-bottom: 20px;
        width: 100%;
    }

    .wrap_article .content_article div.img_list {
        margin-bottom: 20px;
        margin-top: 25px;
    }

    .wrap_article .content_article ul.ul_50 {
        display: inline-block;
        width: 100%;
    }

    .wrap_article .content_article img.img_54 {
        width: 100%;
    }
    .wrap_article .content_article ul.ul_45 {
        display: inline-block;
        width: 100%;
    }
}
@media (max-width: 570px) {
    .risks__img img {
        max-width: 100%
    }
}

.risks__text {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

.risks__down {
    margin-top: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 20px;
}

@media (max-width: 935px) {
    .risks__up {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .risks__down {
        -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.pluces {
    margin-top: 86px;
}

.pluces__title {
    margin-bottom: 30px;
}

.pluces__title h5 {
    font-weight: 700;
    font-size: 24px;
    line-height: 141.6666666667%;
    text-transform: uppercase;
}

.pluces__item {
    margin-bottom: 35px;
    padding-bottom: 30px;
    padding-right: 39px;
    border-bottom: 1px solid #e0e0e0;
}

@media (max-width: 650px) {
    .pluces__item {
        padding-right: 20px;
    }
}

.agents {
    margin-top: 90px;
}

.info-block__title {
    margin-bottom: 30px;
}

.info-block__title h6 {
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    text-transform: uppercase;
}

.info-block__sub-title {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 18px;
    line-height: 111.1111111111%
}

.info-block__list {
    padding-left: 0!important;
}

.info-block__list li:not(:last-child) {
    margin-bottom: 20px;
}

.info-block__list li {
    position: relative;
    padding-left: 25px;
    list-style: none!important;
}

.info-block__list li::before {
    position: absolute;
    content: url("/templates/leto/images/img/status.svg");
    left: 0;
    /*top: 5px;*/
}

.recommend {
    margin-top: 90px;
}

.recommend .text {
    margin-top: 30px;
}